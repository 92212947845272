.edit-schedule {
    clear: both;

    .download-schedule {
        .fa-download {
            cursor: pointer;
        }

        front-royal-spinner {
            margin: 0;
            display: "inline-block";
        }
    }

    .fc .fc-toolbar-title {
        font-size: 1.4em !important;
        margin: 0 !important;
    }

    .fc .fc-button-primary {
        background-color: #4a4a4a !important;
        border-color: #4a4a4a !important;
    }

    .checkbox {
        padding-left: 25px;
    }

    locale-pack-selectize.wide,
    item-groups-editor.wide,
    period-stream-selector.wide {
        .selectize-control {
            width: 100%;
        }
    }

    .edit-playlist-collection {
        border: 1px solid $COLOR_V3_BEIGE_DARKEST;
        border-radius: 4px;
        margin-bottom: 10px;
        padding: 8px;

        .remove-playlist-collection-container {
            display: flex;
            flex-direction: row-reverse;
            margin-top: 10px;
        }
    }

    .playlist-collection-header-container {
        display: flex;

        .playlist-collection-title {
            margin-left: 10px;
        }
    }

    .project-style,
    .specialization-style {
        .selectize-control {
            width: 100%;
        }
    }

    .schedule-wrapper {
        // In this case we want to give the schedule + calendar a bit more space
        margin-left: -56px !important;
        margin-right: -56px !important;
    }

    .schedule-box {
        width: calc(100% - 450px);
        display: inline-block;
        margin-left: 30px;
        vertical-align: top;
        margin-bottom: 50px;
    }

    #periods-calendar-container {
        width: 350px;
        position: sticky;
        display: inline-block;
        top: 10px;
        margin-bottom: 50px;

        .periods-calendar-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1rem;

            .periods-calendar-title {
                font-size: 1.2em;
                font-weight: $FONT_WEIGHT_SEMIBOLD;
                margin: 0;

                i {
                    margin-inline-end: 0.5rem;
                }
            }

            .text-button {
                font-size: 0.85em;
                color: $COLOR_V3_BEIGE_DARKEST;
                background: none;
                border: none;
                text-transform: uppercase;
                transition: 225ms ease-in-out;

                &:hover {
                    color: $COLOR_V3_BLUE;
                }
            }
        }

        .dates {
            text-align: center;

            .end-date {
                opacity: 0.5;
            }
        }

        .fc-basic-view .fc-body .fc-row {
            min-height: 3em;
        }

        .dropdown-menu {
            left: auto;
        }

        #periods-calendar {
            max-width: 350px;
            max-height: 999px;
            overflow: hidden;
            transition: max-height 0.225s ease-in-out;

            &.collapsed {
                max-height: 0px;
            }
        }

        .sub-text {
            display: block;
            transition: opacity 0.225s ease-in-out;
            opacity: 1;

            &.collapsed {
                opacity: 0;
                pointer-events: none;
            }
        }

        .calendar-controls {
            margin-top: 1rem;
            padding-top: 1rem;
            border-top: 1px solid $COLOR_V3_BEIGE_DARK;
            display: flex;
            align-items: center;
            justify-content: space-between;
            transition: margin 0.225s ease, padding 0.225s ease, border-color 0.225s ease;

            &.collapsed {
                margin-top: 0;
                padding-top: 0;
                border-top-color: transparent;
            }

            .publish-control {
                display: flex;
                align-items: center;
                gap: 0.5rem;
            }

            .save-button {
                padding: 0.5rem 1.5rem;
            }
        }
    }

    .dropdown {
        padding-top: 10px;
    }

    // All this is just to get the specificity we need to have
    // these styles get applied
    table > tbody > tr > td {
        .add-button {
            margin: 0px 5px;
            padding: 3px 10px;
            display: block;
            width: calc(100% - 10px);
            border: 1px solid $COLOR_V3_BEIGE_DARK;
            color: $COLOR_V3_BEIGE_BEYOND_DARK;

            &:hover,
            &:active {
                background-color: $COLOR_V3_BLUE;
                border: 1px solid $COLOR_V3_BLUE;
                color: $COLOR_V3_WHITE;
            }

            // If we don't do this, then right after clicking the text fades
            // to white and we can't see it
            &:focus {
                color: $COLOR_V3_BEIGE_BEYOND_DARK;
            }
        }
    }

    // We specify a :not because these styles are affecting the datetimepicker table elements
    table.schedule-table {
        > tbody {
            > tr {
                margin-top: 10px;
                margin-bottom: 10px;

                &.period-box {
                    &.period-style-review {
                        background-color: rgba(#d1f6f1, 0.5);
                    }
                    &.period-style-exam {
                        background-color: rgba(#e8dbf9, 0.5);
                    }
                    &.period-style-project {
                        background-color: rgba(#dcedc8, 0.5);
                    }
                    &.period-style-break {
                        background-color: rgba(#f6d1d4, 0.5);
                    }
                    &.period-style-specialization {
                        background-color: rgba(#f6edd1, 0.5);
                    }
                }

                > td {
                    vertical-align: top;
                    display: inline-block;
                    border: none;
                }

                > td.add-period-row {
                    display: block;
                    text-align: center;
                }

                > td.period-body-column {
                    width: calc(100% - 200px);

                    .title-readonly {
                        max-width: 100%;
                    }

                    .description-readonly {
                        max-width: 100%;
                        margin-bottom: 10px;
                    }

                    .document-readonly {
                        padding-left: 0px;
                    }
                }

                > td.period-header-column {
                    color: $COLOR_V3_BLACK;
                    padding-bottom: 30px;
                    padding-right: 30px;
                    width: 200px;

                    .period-number {
                        font-size: 16px;
                        display: inline-block;
                        font-weight: $FONT_WEIGHT_SEMIBOLD;
                    }

                    .period-start-date,
                    .period-end-date {
                        opacity: 0.5;
                    }

                    .period-reorder {
                        margin-top: 15px;
                    }

                    .period-header-readonly-details {
                        code {
                            word-wrap: break-word;
                        }
                    }
                }
            }
        }
    }

    .project-document-row {
        margin-top: 15px;
    }

    edit-schedule-id-verification-period {
        // should remain the same as: admin-datetimepicker .ref-timezone
        .sub-text {
            font-size: $font-size-base * 0.8;
            opacity: 0.9;
        }
    }

    .edit-period-exercise,
    .edit-period-action {
        position: relative;

        .icon.small.remove {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }

        .form-group {
            padding-left: 43px !important;
            margin: 0px 0 20px !important;
        }
    }
}
